import React from 'react';
import { navigate } from 'gatsby';
import { handleLogin, isLoggedIn } from '../services/auth';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

class Login extends React.Component {
  state = {
    password: ``,
    passwordShown: false,
  };

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    handleLogin(this.state);
  };

  togglePassword = event => {
    this.setState({
      passwordShown: !this.state.passwordShown
    });
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/`);
    }
    return (
      <form
        className="login__form"
        method="post"
        onSubmit={event => {
          this.handleSubmit(event);
          navigate(`/`);
        }}
      >
        <div className="pass-wrapper">
          <input
            type={this.state.passwordShown ? 'text' : 'password'}
            name="password"
            placeholder="Vul je wachtwoord in&hellip;"
            id="password"
            onChange={this.handleUpdate}
          />
          <i onClick={this.togglePassword}>{eye}</i>
        </div>

        <input type="submit" value="Inloggen" className="inline-flex p-3 rounded ml-auto outline-none" />
      </form>
    );
  }
}
export default Login;
